import { Modal } from 'react-bootstrap';
import closeIcon from './../../../assets/icons/close-icon.png';
import leftIcon from './../../../assets/icons/left-icon.png';
import rightIcon from './../../../assets/icons/right-icon.png';
import './styles.scss';
import { Carousel } from 'react-responsive-carousel';

import conference1 from './../../../assets/service-page/Conferences-Summits/View-All-Images/1.jpg';
import conference2 from './../../../assets/service-page/Conferences-Summits/View-All-Images/2.JPG';
import conference3 from './../../../assets/service-page/Conferences-Summits/View-All-Images/3.JPG';
import conference4 from './../../../assets/service-page/Conferences-Summits/View-All-Images/4.jpg';
import conference5 from './../../../assets/service-page/Conferences-Summits/View-All-Images/5.jpg';
import conference6 from './../../../assets/service-page/Conferences-Summits/View-All-Images/6.jpg';
import conference7 from './../../../assets/service-page/Conferences-Summits/View-All-Images/7.jpg';
import conference8 from './../../../assets/service-page/Conferences-Summits/View-All-Images/8.jpg';
import conference9 from './../../../assets/service-page/Conferences-Summits/View-All-Images/9.jpg';
import conference10 from './../../../assets/service-page/Conferences-Summits/View-All-Images/10.jpg';

import corporate1 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/1.jpg';
import corporate2 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/2.jpg';
import corporate3 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/3.jpg';
import corporate4 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/4.JPG';
import corporate5 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/5.jpg';
import corporate6 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/6.jpg';
import corporate7 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/7.jpg';
import corporate8 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/8.jpg';
import corporate9 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/9.jpg';
import corporate10 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/10.jpg';
import corporate11 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/11.jpg';
import corporate12 from './../../../assets/service-page/Corporate-Exhibition/View-All-Images/12.JPG';

import music1 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/1.jpg';
import music2 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/2.jpg';
import music3 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/3.jpg';
import music4 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/4.jpg';
import music5 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/5.jpg';
import music6 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/6.jpg';
import music7 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/7.jpg';
import music8 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/8.JPG';
import music9 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/9.jpg';
import music10 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/10.jpg';
import music11 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/11.jpg';
import music12 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/12.jpg';
import music14 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/13.jpg';
import music13 from './../../../assets/service-page/Music-Festival-Concerts/View-All-Images/14.jpg';

import wedding1 from './../../../assets/service-page/Weddings-Social/View-All-Images/1.jpg';
import wedding2 from './../../../assets/service-page/Weddings-Social/View-All-Images/2.jpg';
import wedding3 from './../../../assets/service-page/Weddings-Social/View-All-Images/3.jpg';
import wedding5 from './../../../assets/service-page/Weddings-Social/View-All-Images/5.jpg';
import wedding6 from './../../../assets/service-page/Weddings-Social/View-All-Images/6.jpg';
import { useEffect, useState } from 'react';


export default function ServiceModal(props){
    const onClose = props.onClose;
    const isVisible = props.isVisible || false;
    const heading = props.serviceType;
    const [images, setImages] = useState([]);
    
    const socialBgs = [
        wedding1, wedding2, wedding3, wedding5, wedding6 
    ];
    
    const corporateBgs = [
        corporate1,
        corporate2,
        corporate3,
        corporate4,
        corporate5,
        corporate6,
        corporate7,
        corporate8,
        corporate9,
        corporate10,
        corporate11,
        corporate12,
    ];

    const musicBgs = [
        music1,
        music2,
        music3,
        music4,
        music5,
        music6,
        music7,
        music8,
        music9,
        music10,
        music11,
        music12,
        music14,
        music13,
    ];
    
    const conferenceBgs = [
        conference1, conference2, conference3, conference4, conference5, conference6,conference7, conference8, conference9, conference10
    ];
    useEffect(()=>{
        if(heading === "Conference & Summits"){
            setImages(conferenceBgs)
        }
        else if(heading === "Corporate & Exhibitions"){
            setImages(corporateBgs)
        }
        else if(heading === "Music Festival & Concerts"){
            setImages(musicBgs)
        }
        else if(heading === "Weddings & Social Events"){
            setImages(socialBgs)
        }
    },[props.serviceType])
    return (
        <div className="service-modal bcon-background width-100 barlow-font-regular text-white">
            <Modal
                dialogClassName='width-100 d-flex'
                contentClassName='width-100 bcon-background'
                style={{
                    opacity: 1
                }}
                centered={true}
                show={isVisible}
                onHide={()=>{onClose()}}
            >
                <Modal.Header className='d-flex border-0 position-relative'>
                    <div className="photo-gallery position-absolute font-15 bcon-text-red w-700">
                        Photo Gallery
                    </div>
                    <div className='mx-auto service-modal-heading border-bottom'>
                        {heading.toUpperCase() || ''}{` (${images.length})`}
                    </div>
                    <div className="close-btn mr-auto">
                        <img onClick={()=>{onClose()}} src={closeIcon} alt="close icon" className="btn-icon" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={true}
                    className="carousel-class service-carousel"
                    renderArrowPrev={(clickHandler, hasPrev) => {
                        return (
                          <div
                            className={`left-arrow`}
                            onClick={clickHandler}
                          >
                            <img src={leftIcon} className="" />
                          </div>
                        );
                      }}
                      renderArrowNext={(clickHandler, hasNext) => {
                        return (
                          <div
                            className={`right-arrow`}
                            onClick={clickHandler}
                          >
                          <img src={rightIcon} className="" />
                          </div>
                        );
                      }}
                    >
                        {
                            images.length > 0 && images.map((image)=>{
                                return (
                                    <div className='carousel-image d-flex'>
                                    <img alt="img" className='' src={image} />
                                </div>
                                )
                            })
                        }
                    </Carousel>
                </Modal.Body>
            </Modal>
        </div>
    )
}