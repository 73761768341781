import './styles.scss';

export default function WeAre(){
    return (
        <>
            <div className="we-are-section barlow-font-regular d-flex flex-column align-items-center justify-content-between text-center">
                <div className="we-are-content d-flex width-70 align-items-center flex-column">
                    <div className="we-are bearpaw-font lh-1 font-weight-bold font-40">
                        WE ARE. WE CREATE. <br/>
                        WE CONNECT YOUR VISION TO AN EXPERINCE
                    </div>
                </div>
                <div className="we-are-description width-40 font-12 text-center">
                Our forte in ideating, servicing, and managing which led us to the forefront of becoming a leading event management company in the UK.
                </div>
            </div>
        </>
    )
}