import React, { useState, useEffect } from 'react';
import './styles.scss';
import logo from './../../assets/bcon-logo.png';
import uk from './../../assets/icons/uk-icon.png';
import { Link } from 'react-router-dom';
import BconButton from '../../components/button/button.component';
import { useDispatch, useSelector } from 'react-redux';

const Navbar = (props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [currentTab, setCurrentTab] = useState('');
  const toggleModal = props.toggleModal;
  const globalState = useSelector(state => state);
  const dispatch = useDispatch();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    const tokens = window.location.href.split('/');
    setCurrentTab(`${tokens[tokens.length - 1].charAt(0).toUpperCase() + tokens[tokens.length - 1].slice(1)}`)

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);

  const toggleTabs = (tab) => {
    if(!globalState.userInteracted){
      dispatch({type:'TOGGLE_AUDIO'})
    }
    setCurrentTab(tab);
  }
  return (
    <>
      <div className='navbar-bg d-flex flex-column barlow-font-regular justify-content-center mb-5' style={{ top: visible ? '0' : '-100px' }}>
      <div className="white-strip bg-white width-100">
        <marquee><div className="text-dark">🚩 <span className='w-700 text-danger'>WOW</span> - <span className='w-700'>We organize worldwide</span> &nbsp;&nbsp; | &nbsp;&nbsp; Currently working from <img src={uk} className='uk-icon-nav' /></div></marquee>
      </div>
      <div className='position-relative'>
          <Link to="/home">
          <div className="nav-brand">
            <img className='brand-img' src={logo} alt="Bconnect"/>
          </div>
          </Link>
          <div className="align-self-center width-100 li-items d-flex justify-content-center">
            <Link onClick={()=>{toggleTabs('Home')}} to="/home">
              <div className={`item ${currentTab === 'Home'  ? 'active-tab' : ''}`}>
                Home
              </div>
            </Link>
            <Link onClick={()=>{toggleTabs('Services')}} to="/services">
              <div className={`item ${currentTab === 'Services'  ? 'active-tab' : ''}`}>
                Services
              </div>
            </Link>
            <Link onClick={()=>{toggleTabs('Events')}} to="/events">
              <div className={`item ${currentTab === 'Events'  ? 'active-tab' : ''}`}>
                Events
              </div>
            </Link>
          </div>
          <div onClick={()=>{toggleModal()}} className="nav-gaq my-3 d-flex align-items-center position-absolute my-auto width-10">
            <BconButton btnText="Get a quote" className={`width-100 ${window.location.href.includes('admin') ?  'nav-gaq-hide' : ''}`} dropShadow={true}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
