import './styles.scss';

export default function BconButton(props){
    const {onSubmit} = props
    const btnText = props.btnText || 'Submit';
    const className = props.className || '';
    const shadow =  props.dropShadow || false;

    const handleClick = () => {
        if (onSubmit) {
          onSubmit();
        }
      };

    return (
        <div 
            className={`${className} barlow-font-regular ${shadow ? 'drop-shadow' : ''} text-center bcon-button p-2`}
            onClick={handleClick}>
                {btnText}
        </div>
    )
}