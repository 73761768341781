import { Link } from 'react-router-dom';
import BconButton from '../../components/button/button.component';
import logo from './../../assets/bcon-black.png';
import ukIcon from './../../assets/icons/uk-icon.png';
import './styles.scss';
import GetAQuote from '../get-a-quote/get-a-quote.component';
import { useState } from 'react';

export default function Footer(){
    const [isModalVisible, setModalVisible] = useState(false);


    const modalToggle = () => {
        setModalVisible(!isModalVisible);
    }

    return (
        <div className="footer bg-white d-flex width-100 barlow-font-regular">
            <GetAQuote key={Date.now()} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/>
            <div className="footer-left bg-white text-dark border-radius width-30 p-3">
                <div className="width-90 footer-left-container justify-content-between d-flex align-items-start flex-column">
                    <div className="footer-left-heading bearpaw-font lh-1 barlow-font-bold bcon-text-red font-30 width-80">
                        Plan Your Event<br/> <span className="bearpaw-font font-today font-40">TODAY </span>!
                    </div>
                    <div className="collabs font mb-4">
                        <span className="barlow-font-bold">For Collaboration</span> - Execution Partners, Social & corporate Gifting, Technical Partners, and more 
                    </div>
                    <div className='width-100' onClick={()=>{setModalVisible(true)}}>
                        <BconButton dropShadow className="mt-4 width-30 contact-us-btn" btnText="Contact Us"/>
                    </div>
                </div>
            </div>
            <div className="footer-right d-flex flex-column justify-content-between border-radius width-70 bg-white text-dark">
                <div className="upper-portion d-flex justify-content-between">
                    <div className="footer-links width-80 d-flex">
                        <Link style={{textDecoration: 'none'}} to="/home"><div className="text-dark footer-link">Home</div></Link>
                        <Link style={{textDecoration: 'none'}} to="/events"><div className="text-dark footer-link">Events</div></Link>
                        <Link style={{textDecoration: 'none'}} to="/services"><div className="text-dark footer-link">Services</div></Link>
                    </div>
                    <div className="bcon-logo-footer width-15">
                        <img src={logo} className='width-100'/>
                    </div>
                </div>
                <div className="lower-portion d-flex">
                    <div className='lower-items'>
                        <div className="footer-heading barlow-font-bold">Address</div>
                        <div className=" flex-column footer-items d-flex">
                            <div>Lincolnshire, London</div>
                            <div className='d-flex'>
                                <img className='uk-icon' src={ukIcon}/>
                                <div>United Kingdom</div>
                            </div>
                        </div>
                    </div>
                    <div className='lower-items'>
                        <div className="width-100 footer-heading barlow-font-bold">Phone Number</div>
                        <div className="width-100 footer-items">+44 7935789686</div>
                        <div className="width-100 footer-items">+44 7540580296</div>
                    </div>
                    <div className='lower-items'>
                        <div className="footer-heading barlow-font-bold">Email</div>
                        <div className="footer-items">bijju@bconnectevents.com</div>
                        <div className="footer-items">betty@bconnectevents.com</div>
                    </div>
                </div>
                <div className={`designed-by text-dark align-self-end mt-5 ${window.location.href.includes('home') ? '' : 'nav-gaq-hide'}`}>
                    Designed By <span className='barlow-font-regular w-700 text-underline'><a className={`dribble-link`} href='https://dribbble.com/mohitgopal' target='_blank'>MannMOGKala</a></span>
                </div>
            </div>
        </div>
    )
}