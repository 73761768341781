import { useState } from "react";
import Collage from "../collage/collage.component";
import ConnectWith from "../connect-with/connect-with.component";
import Footer from "../footer/footer.component";
import FoundersNote from "../founders-note/founders-note.component";
import GetAQuote from "../get-a-quote/get-a-quote.component";
import HeroVideo from "../hero-video/hero-video.component";
import Navbar from "../navbar/navbar.component";
import OurClients from "../our-clients/our-clients.component";
import OurServices from "../our-services/our-services.component";
import Testimonials from "../testimonials/testimonials.component";
import WeAre from "../we-are/we-are.component";
import "./styles.scss";
import { Bounce, Fade, Zoom } from "react-reveal";

export default function HomePage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalToggle = () => {
      setIsModalVisible(!isModalVisible);
  }
  return (
    <div className="fade-in-animation">
      {/* <div className="bg-white position-absolute top-0">WOW</div> */} 
      <Navbar toggleModal={()=>{modalToggle()}}/>
      <HeroVideo/>
      <Zoom>
      <WeAre/>
      <ConnectWith/>
      </Zoom>
      <FoundersNote/>
      <Bounce>
      <OurServices/>
      </Bounce>
      <Fade>
      {/* <Testimonials/> */}
      </Fade>
      <OurClients/>
      <Collage/>
      <Footer/>
      <GetAQuote key={Date.now()} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/>
    </div>
  );
}
