import Note from './note/note.component';
import './styles.scss';

export default function FoundersNote(){
    return (
        <div className="founders-note pb-5 bg-white">
            <p className='bearpaw-font founders-title font-70 text-center bcon-text-red'>THE FOUNDERS</p>
            <Note/>
        </div>
    )
}