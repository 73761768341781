import './styles.scss'
import CreateEventForm from "../create-events-form/create-events-form";
import Navbar from './../../navbar/navbar.component';
import {useLocation,useParams} from 'react-router-dom'

export default function CreateEvent(props){

    const { id } = useParams();

    const locationObj = useLocation()

    const categoryId = locationObj.search.split("=")[1]

    // need to fetch the url and path from path
    return (
        <div>
            <Navbar/>
            <div className="create-events"> 
                <CreateEventForm eventId = {id} categoryId={categoryId}/>
            </div>
        </div>
    )
}