import './styles.scss';
import fNote from './../../../assets/bijju-note.png';
import bNote from './../../../assets/betty-note.png';

export default function Note(){
    return (
        <div className="d-flex align-items-center justify-content-center founders-note-imgs pb-5 justify-content-around position-relative">
            <img className='width-50 founders-note-img' src={fNote}/>
            <img className='width-50 founders-note-img' src={bNote}/>
        </div>
    )
}