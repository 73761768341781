import { useEffect, useState } from 'react';
import Service from './service/service.component';
import './styles.scss';
import { EventType } from '../../utils/enums/events-category.enum.ts';

import social1 from './../../assets/images/social-1.jpg';
import social2 from './../../assets/images/social-2.jpg';

import corporate1 from './../../assets/images/corporate-1.jpg';
import corporate2 from './../../assets/images/corporate-2.jpg';
import corporate3 from './../../assets/images/corporate-3.jpg';
import corporate4 from './../../assets/images/corporate-4.jpg';

import conference1 from './../../assets/images/conference-1.JPG';
import conference2 from './../../assets/images/conference-2.JPG';

import exhibition1 from './../../assets/images/exhibition-1.jpg';

import music1 from './../../assets/images/music-fest-1.jpg';
import music2 from './../../assets/images/music-fest-4.jpg';
import music3 from './../../assets/images/music-fest-3.jpg';
import { Link } from 'react-router-dom';

let intId = [];

export default function OurServices(){

    const [music, setMusic] = useState(false);
    const [social, setSocial] = useState(false);
    const [conference, setConference] = useState(false);
    const [exhibitions, setExhibition] = useState(false);
    const [corporate, setCorporate] = useState(false);
    const [none, setNone] = useState(true);
    const [heading, setHeading] = useState(false);
    const [counter, setCounter] = useState(0);

    const socialBgs = [
        social1, social2
    ];

    const corporateBgs = [
        corporate1, corporate2, corporate3, corporate4
    ];
    
    const exhibitionBgs = [
        exhibition1
    ];

    const musicBgs = [
        music1, music2, music3
    ];
    
    const conferenceBgs = [
        conference1, conference2
    ];

    function onServiceHover(serviceName){
        if(serviceName === EventType.Music){
            setSocial(true);
            setCorporate(true);
            setConference(true);
            setExhibition(true);
            setHeading(true);
            setNone(false);
            increaseCounter(musicBgs.length);
        }
        if(serviceName === EventType.Conference){
            setSocial(true);
            setCorporate(true);
            setMusic(true);
            setExhibition(true);
            setHeading(true);
            setNone(false);
            increaseCounter(conferenceBgs.length);
        }
        if(serviceName === EventType.Corporate){
            setSocial(true);
            setMusic(true);
            setConference(true);
            setExhibition(true);
            setHeading(true);
            setNone(false);
            increaseCounter(corporateBgs.length);
        }
        if(serviceName === EventType.Social){
            setMusic(true);
            setCorporate(true);
            setConference(true);
            setExhibition(true);
            setHeading(true);
            setNone(false);
            increaseCounter(socialBgs.length);
        }
        if(serviceName === EventType.Exhibitions){
            setSocial(true);
            setCorporate(true);
            setConference(true);
            setMusic(true);
            setHeading(true);
            setNone(false);
            increaseCounter(exhibitionBgs.length);
        }
    }

    function onHoverOut(){
        setSocial(false);
        setCorporate(false);
        setConference(false);
        setExhibition(false);
        setMusic(false);
        setHeading(false);
        setNone(true);
        stopCounter();
    }

    function increaseCounter(ceil){
        let count = 0;
        const intervalId = setInterval(()=>{
            if(ceil - 1 === count){
                count = 0;
                setCounter(0);
            }
            else{
                const counterVal = counter
                setCounter(counter => counter + 1);
                count++;
            }
        }, 4000);
        intId.push(intervalId); 
    }

    function stopCounter(){
        setCounter(0);
        intId.forEach((id)=>{
            clearInterval(intId);
            intId.splice(intId.indexOf(id), 1); 
        })
    }

    return (
        <div 
            style={
                (!music && !none) ? 
                {backgroundImage: `url(
                    ${musicBgs[counter]}
                )`} 
                : 
                (!exhibitions && !none) ? 
                {backgroundImage: `url(
                    ${exhibitionBgs[counter]}
                )`} 
                : 
                (!conference && !none) ? 
                {backgroundImage: `url(
                    ${conferenceBgs[counter]}
                )`} 
                : 
                (!corporate && !none) ? 
                {backgroundImage: `url(
                    ${corporateBgs[counter]}
                )`} 
                : 
                (!social && !none) ? 
                {backgroundImage: `url(
                    ${socialBgs[counter]}
                )`} 
                : {}
            } className='our-service-container py-3 d-flex flex-column align-items-center'
        >
            <div className={`${heading ? 'opacity-zero' : ''} bearpaw-font our-services-title font-80 text-center lh-1 mt-5`}>Services</div>
            <div className="d-flex flex-column align-items-center">
                <div className='d-flex'>
                    <Service className={music ? `hidden` : ''} id = {1} onHover = {(sName)=>{onServiceHover(sName)}} onHoverOut={()=>{onHoverOut()}} serviceName = "Music Festivals & Concerts"/>
                    <Service className={social ? `hidden` : '' } id = {2} onHover = {(sName)=>{onServiceHover(sName)}} onHoverOut={()=>{onHoverOut()}} serviceName = "Social & Weddings"/>
                    <Service className={conference ? `hidden` : ''} id = {3} onHover = {(sName)=>{onServiceHover(sName)}} onHoverOut={()=>{onHoverOut()}} serviceName = "Conference & Summits"/>
                </div>
                <div className='d-flex'>
                    <Service className={exhibitions ? `hidden` : ''} id = {4} onHover = {(sName)=>{onServiceHover(sName)}} onHoverOut={()=>{onHoverOut()}} serviceName = "Exhibitions"/>
                    <Service className={corporate ? `hidden` : ''} id = {5} onHover = {(sName)=>{onServiceHover(sName)}} onHoverOut={()=>{onHoverOut()}} serviceName = "Corporate"/>
                </div>
            </div>
            <Link to="/services"><div className={`${heading ? `opacity-zero` : ''} read-more text-decoration-underline mt-3`}>Read Details</div></Link>
        </div>
    )
}