import ReactPlayer from "react-player";
import bconLogo from './../../assets/bconnect-logo.png'
import './styles.scss';
import ReactTyped from "react-typed";

export default function HeroVideo(){
    return (
        <div className="hero-video d-flex justify-content-center position-relative">
            <ReactPlayer
                url={process.env.REACT_APP_VIDEO_URL}
                loop={true}
                width={'100%'}
                height={'auto'}
                muted={true}
                playing={true}
                playsinline={true}
                className="bcon-hero-video"
            />
            <div className="position-absolute video-body video-overlay-content align-items-center d-flex justify-content-center width-50 mx-auto">
                <div className="bcon-logo-hero d-flex align-items-center">
                    <img src={bconLogo} className="video-icon"/>
                </div>
                <div className="video-title barlow-font-bold text-white font-30">
                    <ReactTyped className="video-transtition" strings={["Where Imagination Meets Execution"]} typeSpeed={25} loop={false} />
                </div>
            </div>
        </div>
    )
}