import './styles.scss';
import penIcon from './../../../../assets/pen.png';
import binIcon from './../../../../assets/trash.png';
import { Link } from 'react-router-dom';
import ConfirmDelete from './../../../confirm-delete-modal/confirm-delete-modal';
import { useState } from 'react';
import {deleteEvent} from './../../../../api/events.api';

export default function AdminEventItem(props) {
    const event = props.event;
    const [isModalVisible, setModalVisible] = useState();
    const onClose = async (flag, eventId) => {
        if(flag){
            setModalVisible(false);
            await deleteEventHd();
        }
        else{
            setModalVisible(false);
        }
    }
    const deleteEventHd = async () => {
        await props.handleDelete(event);
    }
    return (
        <div className='d-flex justify-content-between p-3 px-5 admin-event-item m-3'>
            <div className='d-flex justify-content-between width-60'>
                <div className='width-30'>
                    {event.name}
                </div>
                <div className='width-30'>
                    {event.location}
                </div>
                <div className='width-30'>
                    {event.startDate}
                </div>
            </div>
                <div className="ctrl-btns d-flex">
                    <ConfirmDelete onClose={(e)=>{onClose(e)}} isVisible={isModalVisible} title="Are you sure you want to delete this event?"/>
                    {/* <Link to={`/admin/create-update-events/${event.id}?categoryId=${event.category}`}>
                        <div className="update-btn mx-4">
                            <img src={penIcon} className='width-100' alt=""/>
                        </div>
                    </Link> */}
                    <div onTruce={(e)=>{setModalVisible(e)}} onClick={()=>{setModalVisible(true)}} className="update-btn update-btn-bin">
                        <img src={binIcon} className='width-100' alt=""/>
                    </div>
                </div>
        </div>
    )
}