import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import UploadButton from '../helper/upload-button/upload-button';

const ImageUploaderWithPriority = (props) => {
  const uploadImgHandler = props.uploadImg;
  const [images, setImages] = useState([]);
  const [imageOrder, setImageOrder] = useState([]);


  const memoizedhandleImageUploadMethod = useCallback(
    (newValue) => {
        // console.log("🚀 ~ file: image-uploader.jsx:11 ~ memoizedhandleImageUploadMethod ~ newImages:", newValue)
        uploadImgHandler(newValue);
    },
    [uploadImgHandler]
  );

//   const handleImageReposition = (oldIndex, newIndex) => {
    // const updatedOrder = [...imageOrder];
    // updatedOrder.splice(newIndex, 0, updatedOrder.splice(oldIndex, 1)[0]);
    // setImageOrder(updatedOrder);
    // console.log("Image needs to be repositioned")
//   };


  useEffect(()=>{
    // console.log("Use Effect")
    if (images.length > 0) {
        // console.log("Images arr: ",URL.createObjectURL(images[images.length-1]))
    }
  },[memoizedhandleImageUploadMethod,images])


  return (
    <div>
      {images.length > 0 ? <div>
        {images && images.length > 0 && images.map((image, index) => {
            // console.log("imageUrl: ",URL.createObjectURL(image))
            return (
          <div key={index}>
        {/* //     <img src={URL.createObjectURL(image)} alt={`img-${index}`} /> */}
           </div>
        )})}
      </div> : ''}
      <UploadButton fn={(image)=>memoizedhandleImageUploadMethod(image)}></UploadButton>
    </div>
  );
};

export default ImageUploaderWithPriority;
