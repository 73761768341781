import ReactSelect from 'react-select';
import './styles.scss';
import EventCard from './event-card/event-card.component.jsx';
import { useEffect, useState } from 'react';
import EventClients from './events-clients/events-clients.component.jsx';
import EventModal from './event-modal/event-modal.component.jsx';
import { getEvents, getEventById } from '../../../api/events.api.js';
import pageNotFound from './../../../assets/not-found.jpg';
import OurClients from '../../our-clients/our-clients.component.jsx';

export default function EventsBody(props){

    
    const [current, setCurrent] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentEvent, setCurrentEvent] = useState();
    const [events, setEvents] = useState([]);
    const [categories, setCategories] = useState([]);
    const isLoadingfn = props.showLoader;
    const [category, setCategory] = useState({value: null, label: 'All'});
    const toggleGAQModal = props.toggleGAQModal;
    const onPageChange = (value) => {
        setCurrent(value);
    }
    useEffect(()=>{
        setCategories(props?.categories);
        setIsLoading(true);
        getEventData();
    },[category])


    const modalToggle = async (eventId, categoryId) => {
        if(eventId && categoryId)
            await getCurrentEvent(eventId, categoryId);
        setIsModalVisible(!isModalVisible);
    }
    const getCurrentEvent = async (eventId, categoryId) => {
        const event = await getEventById(eventId, categoryId);
        setCurrentEvent(event.data.data);
    }
    const getEventData = async () => {
        const eventsData = await getEvents(category?.value);
        setIsLoading(false);
        setEvents(eventsData?.data?.data?.data || []);
    }

    const customStyles = {
        option: (base, { isFocused }) => {
        return {
          ...base,
          backgroundColor: isFocused ? "rgba(190, 68, 68, 0.4)" : "",
          color: isFocused ? "black" : ""
        };
      }
    };
    return (
        <>
            {currentEvent ? <EventModal toggleGAQModal={()=>{modalToggle();toggleGAQModal()}} event={currentEvent} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/> : ""}
            <div className="events-body position-relative bg-white d-flex flex-column justify-content-between">
                <div className='d-flex justify-content-between'>
                    <div className="service-heading text-dark font-18 barlow-font-medium">
                        All Events
                    </div>
                    <div className="category-filter d-flex align-items-center">
                        <div className='width-60'>Category:</div>
                        <ReactSelect
                            value={category}
                            className='width-100'
                            options={
                                categories
                            }
                            onChange={(e)=>{
                                setCategory(e);
                            }}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="event-cards flex-wrap d-flex pb-5">
                    {events.length > 0 ? events.map((event, key)=>{
                      return <EventCard event={event} key={key} toggleModal={(eventId, category)=>modalToggle(eventId, category)}/>  
                    }) : ''}
                    {events.length === 0 && !isLoading ? 
                                        <div className='width-100 d-flex justify-content-center'>
                                        <img src={pageNotFound}  className='width-50'/>
                                    </div>
                    : ''}
                </div>
                <div className='align-self-end'>
                    {/* <TconPagination total={total} currPage={current} 
                    onPageChange={onPageChange} pageItemsCount={5}/> */}
                </div>
                {isLoading && (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
                )}
            </div>
            <div className='bg-dark'>
                <OurClients/>
            </div>
        </>
    )
}