import './styles.scss';
import collage from './../../assets/bcon-circles.png';
import { Zoom } from 'react-reveal';

export default function ConnectWith(){
    return (
        <>
            <div className="connect-with-bconnect barlow-font-regular align-items-center justify-content-between d-flex py-5">
                <div className="left-section width-30">
                    <div className="connect-with bearpaw-font lh-1 font-40 bcon-text-red">
                        Elevate With
                    </div>
                    <div className="bconnect bearpaw-font lh-1 font-70 bcon-text-red">
                        BCONNECT.
                    </div>
                    <div className="connect-with-description font-12">
                    Throughout these years of expertise work, we have managed to synchronize our thoughts with our client's concepts to yield the best results. We have led the team towards better possibilities for the execution of events, which involves planning, ironing out challenges, and thus implementing plans.
                    </div>
                </div>
                <Zoom>
                    <div className="right-section width-60 my-4">
                        <img className='connect-with-collage' src={collage} alt="Bconnect Collage" />
                    </div>
                </Zoom>
            </div>
        </>
    )
}