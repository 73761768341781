import { Link } from 'react-router-dom';
import './styles.scss'

export default function Service(props){
    const serviceName = props.serviceName || 'Service';
    const onHoverFunction = props.onHover;
    const onHoverOutFunction = props.onHoverOut;
    return (
        <Link style={{textDecoration: 'none'}} to="/services"><div onMouseEnter={
            () => onHoverFunction(props.serviceName)
        }
        onMouseLeave={
            () => onHoverOutFunction()
        }
        className={`${props?.className} service-circle barlow-font-regular p-3 text-center d-flex justify-content-center align-items-center`}>
            <div className="service-name font-15">
                {serviceName}
            </div>
        </div>
        </Link>
    )
}