// reducers.js
export const initialState = {
    // Define initial state here
    audio: false,
    userInteracted: false
  };
  
  export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      // Define reducer cases here
      case 'TOGGLE_AUDIO':
        return {
          ...state,
          audio: !state.audio,
          userInteracted: true
        }
      default:
        return state;
    }
  };
