import { Modal } from "react-bootstrap";
import locationIcon from './../../../../assets/icons/location.png';
import leftIcon from './../../../../assets/icons/left-icon.png';
import rightIcon from './../../../../assets/icons/right-icon.png';
import './styles.scss';
import eventModalImg from './../../../../assets/event-modal.png';
import BconButton from "../../../../components/button/button.component";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import closeIcon from './../../../../assets/icons/close-icon.png';
import moment from "moment";

export default function EventModal(props){
    const isVisible = props.isVisible || false;
    const toggleGAQModal = props.toggleGAQModal;
    const onClose = props.onClose;
    const event = props.event;
    const images = event.images.images || [];
    return (
        <div className="event-modal barlow-font-regular text-white width-100">
            <Modal onHide={()=>onClose()} contentClassName="info-modal px-1 py-1" dialogClassName="modal-dialog" show={isVisible} style={{opacity:1}} centered className="width-100">
                <Modal.Header className="border-0 d-flex justify-content-end px-3 pt-3 pb-0">
                    <img onClick={()=>{onClose()}} src={closeIcon} alt="close icon" className="btn-icon" />
                </Modal.Header>
                    <div className="d-flex modal-body-container py-1 px-1">
                        <div className="modal-left-content width-60">
                            <div className="">
                            <Carousel
                                infiniteLoop={true}
                                autoPlay={true}
                                showStatus={false}
                                showIndicators={false}
                                className="carousel-class"
                                renderArrowPrev={(clickHandler, hasPrev) => {
                                    return (
                                      <div
                                        className={` left-arrow`}
                                        onClick={clickHandler}
                                      >
                                        <img src={leftIcon} className="" />
                                      </div>
                                    );
                                  }}
                                  renderArrowNext={(clickHandler, hasNext) => {
                                    return (
                                      <div
                                        className={`
                                        ${
                                            hasNext ? 'absolute' : 'hidden'
                                        }
                                         right-arrow`}
                                        onClick={clickHandler}
                                      >
                                      <img src={rightIcon} className="" />
                                      </div>
                                    );
                                  }}
                            >
                            {images && images.map((image)=>{
                                return (<div className="event-modal-img-container">
                                    <img className="event-modal-img" src={image.imageUrl} />
                                </div>)
                            })}
                            </Carousel>
                            </div>
                        </div>
                        <div className="modal-right-content width-50">
                            <div className="event-modal-header">
                                <div className="barlow-font-medium event-modal-heading font-25">
                                    {event?.name}
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="year-of-event barlow-font-medium font-12 text-center">{moment(event.startDate).year()}</div>
                                    <div className="location-icon d-flex">
                                        <div className="mx-3 d-flex align-items-center">
                                            <img src={locationIcon} className="location-icon-img mx-1" />
                                            <div className="location">{event?.location}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: event?.description}} className="event-modal-description"></div>
                            <div onClick={()=>{toggleGAQModal()}}>
                                <BconButton onClick={()=>{toggleGAQModal()}} btnText="Interested? - Get a quote!" className="py-2 event-modal-cta width-50 mt-5" dropShadow={true}/>
                            </div>
                        </div>
                    </div>
            </Modal>
        </div>
    )
}