import { useEffect, useState } from "react";
import Footer from "../footer/footer.component";
import GetAQuote from "../get-a-quote/get-a-quote.component";
import Navbar from "../navbar/navbar.component";
import HeroImage from "../services/hero-image/hero-image.component";
import bg from "./../../assets/our-events-hero.png";
import EventsBody from "./events-body/events-body.component";
import { getCategories } from "../../api/events.api";
import './styles.scss';

export default function Events(){
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    useEffect(()=>{
        getCategoriesList();
    },[])

    const modalToggle = () => {
        setIsModalVisible(!isModalVisible);
    }

    const getCategoriesList = async () => {
        const categories = await getCategories();
        const categoryAdapter = [{label: 'All', value: null}];
        categories.data.data.forEach((category)=>{
            categoryAdapter.push({label: category.name, value: category.id});
        })
        setCategories(categoryAdapter);
    }

    return(
        <div className="events-container fade-in-animation">
            <Navbar toggleModal={()=>{modalToggle()}}/>
            <HeroImage bgImg={bg} heading="Our Events"/>
            {categories.length > 0 ? <EventsBody showLoader={(e)=>{setIsLoading(e)}} categories={categories} toggleGAQModal={()=>{modalToggle()}}/> : ''}
            <Footer/>
            <GetAQuote key={Date.now()} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/>
            {isLoading && (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    )
}