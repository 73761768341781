import { EventType } from './../../../utils/enums/events-category.enum.ts';
import ServiceType from './service-type/service-type.component';
import collage from './../../../assets/service-collage.png';
import './styles.scss';
import ServiceModal from '../service-modal/service-modal.component.jsx';
import { useState } from 'react';

export default function ServiceBody(){
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentService, setCurrentService] = useState('');

    const modalToggle = (serviceType) => {
        setCurrentService(serviceType);
        setIsModalVisible(!isModalVisible);
    }

    return (
        <div className="service-body d-flex flex-column bg-white barlow-font-regular">
            <div className='d-flex'>
                <div className="service-heading text-dark font-18 barlow-font-medium">All services</div>
            </div>
            <ServiceType onServiceClick={(e)=>{modalToggle(e)}} heading={"Music Festival & Concerts"} description="Immerse yourself in the pulsating heartbeat of live music as we embark on a journey through the electrifying realm of music festivals and concerts. These vibrant gatherings are not merely events but kaleidoscopes of sound, color, and energy that transcend the ordinary, creating memories that resonate long after the final note fades away."/>
            <ServiceType onServiceClick={(e)=>{modalToggle(e)}} heading={"Conference & Summits"} description="Embark on a journey of professional growth and innovation as we explore the dynamic world of conferences and summits. These transformative gatherings serve as epicenters of knowledge exchange, fostering collaboration, and propelling industries forward. Whether you're a seasoned professional or an emerging talent, these events offer a gateway to cutting-edge insights, networking opportunities, and a glimpse into the future of your field."/>
            <ServiceType onServiceClick={(e)=>{modalToggle(e)}} heading={"Weddings & Social Events"} description="Enter a world where dreams come to life and memories are etched in the heart forever. Welcome to the enchanting realm of weddings and social events, where every detail is a brushstroke in the canvas of celebration. Whether you're planning a romantic wedding or a social gathering, our curated events promise to transform your vision into an unforgettable reality."/>
            <ServiceType onServiceClick={(e)=>{modalToggle(e)}} heading={"Corporate & Exhibitions"} description="Step into a world where business meets brilliance, and innovation intertwines with inspiration. Welcome to the dynamic world of exhibitions and corporate events, where ideas are showcased, connections are formed, and businesses are propelled into the future. Whether you're planning a product launch, industry expo, or corporate gathering, our tailored events promise to elevate your brand and leave a lasting impression on your audience."/>
                <div className="service-collage width-100 responsive-padding">
                    <img src={collage} className='width-100' />
                </div>
            {currentService && <ServiceModal serviceType={currentService} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/>}
        </div>
    )
}