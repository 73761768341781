export const apiRoutes = {
    GET_EVENTS : 'api/v1/events/list',
    GET_EVENT_BY_ID: 'api/v1/events/{id}',
    GET_CATEGORIES: 'api/v1/category/list',
    CREATE_EVENT: 'api/v1/events',
    UPDATE_EVENT:  'api/v1/events/update/{id}',
    GET_PRESIGNED_URL: 'api/v1/images/get-presigned-url/{id}',
    GET_PREDIGNED_UPLOAD_URL: 'api/v1/images/get-presigned-upload-url',
    GET_MULTIPLE_PRESIGNED_UPLOAD_URL: 'api/v1/images/get-multiple-presigned-upload-url',
    GET_IS_EVENT_VALID: 'api/v1/events/isValid',
    DELETE_IMAGE: 'api/v1/images/delete/{id}',
    DELETE_EVENT: 'api/v1/events/archive/{id}'
}