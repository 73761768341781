import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/home/home.component';
import './App.scss';
import HomePage from './pages/homepage/home-page.component';
import Services from './pages/services/services.component';
import Events from './pages/events/events.component';
import ScrollToTop from './utils/helpers/scroll-to-top.component';
import CreateEvent from './pages/admin/create-events/create-events';
import UpdateEvent from './pages/admin/update-events/update-events';
import ReactSound  from 'react-sound';
import playBtn from './assets/icons/play-btn.png';
import pauseBtn from './assets/icons/pause-btn.png';
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const dispatch = useDispatch();
  const globalState = useSelector(state => state);
  function toggleAudio(){
    dispatch({type:'TOGGLE_AUDIO'})
  }

  return (
    <div className='position-relative'>
      <div onClick={()=>{toggleAudio();}} className="fixed-button">
        <img src={globalState.audio && globalState.userInteracted ? pauseBtn : playBtn} className='play-btn width-100'/>
      </div>
      <ReactSound
          url={process.env.REACT_APP_MUSIC_URL}
          playStatus={globalState.audio  ? ReactSound.status.PLAYING : ReactSound.status.PAUSED}
          loop={true}
      />
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={ <Navigate to="/home" /> } />
        <Route path="/home" element={<HomePage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/events" element={<Events />} />
        <Route path="/admin/events" element={<UpdateEvent />} />
        <Route path="/admin/create-update-events/:id" element={<CreateEvent />} />
        <Route path="/admin/create-update-events" element={<CreateEvent />} />
        <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
