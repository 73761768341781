import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import closeIcon from './../../assets/icons/close-icon.png';
import BconButton from '../../components/button/button.component';
import './styles.scss';
import { toast, ToastContainer } from 'react-toastify';

export default function GetAQuote(props) {
  const [event, setEvent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectDetails: '',
  });

  const isVisible = props.isVisible;
  const onClose = props.onClose;

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const submitHandler = () => {
    if(formData.name.length < 1){
      toast.error('Please fill the name field');
      return;
    }
    if(formData.email.length < 1){
      toast.error('Please fill the email field');
      return;
    }
    if(formData.projectDetails.length < 1){
      toast.error('Please fill the project description');
      return;
    }
    if(!event){
      toast.error('Please select the event category');
      return;
    }
    setIsLoading(true)
    const data = { ...formData, event };

    axios
      .post(
        process.env.REACT_APP_SHEET_URL,
        data
      )
      .then((response) => {
        toast.success('Form submitted successfully');
        setTimeout(()=>{
          onClose();
          setIsLoading(false)
        },1500)
      })
      .catch((error) => {
        toast.error('Error submitting form. Please try again later');
        onClose();
        setIsLoading(false)
      });
  };

  return (
    <div className="gaq barlow-font-regular">
      <Modal
        onHide={() => {
          onClose();
        }}
        contentClassName="position-relative info-modal px-1 py-2"
        dialogClassName="modal-dialog"
        show={isVisible}
        style={{ opacity: 1 }}
        centered
        className="width-100"
      >
        <Modal.Header className='barlow-font-medium d-flex justify-content-between bcon-text-red font-20 border-0'>
          <div className='d-flex justify-content-between width-100 align-items-center'>
            <div className='mx-auto lh-1 gaq-heading justify-self-center bearpaw-font font-60'>LET'S CONNECT FOR...</div>
          </div>
        </Modal.Header>
        <Modal.Body className='gaq-modal-body'>
          <div className='d-flex justify-content-between flex-column align-items-center width-100'>
            {/* <form onSubmit={submitHandler}> */}
              <div className='d-flex flex-column justify-content-center align-items-center width-100 mt-3 form-part-gaq'>
                <div className="event-types width-40 flex-wrap justify-content-center d-flex">
                  <div onClick={() => { setEvent('Music Festivals') }} className={`${event === 'Music Festivals' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Music Festivals</div>
                  <div onClick={() => { setEvent('Concerts') }} className={`${event === 'Concerts' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Concerts</div>
                  <div onClick={() => { setEvent('Summit') }} className={`${event === 'Summit' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Summit</div>
                  <div onClick={() => { setEvent('Conferences') }} className={`${event === 'Conferences' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Conferences</div>
                  <div onClick={() => { setEvent('Exhibitions') }} className={`${event === 'Exhibitions' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Exhibitions</div>
                  <div onClick={() => { setEvent('Corporate') }} className={`${event === 'Corporate' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Corporate</div>
                  <div onClick={() => { setEvent('Social') }} className={`${event === 'Social' ? 'event-type-selected' : ''} event-type m-1 mb-2`}>Social</div>
                </div>
                <div className="inputs width-60">
                  <div className='d-flex justify-content-between top-inputs my-4' placeholder='Your Name'>
                    {/* <Form.Group controlId="name"> */}
                      {/* <Form.Label>Your Name</Form.Label> */}
                      <input
                        className='gaq-input top-input mr-4 width-45' 
                        type='text'
                        placeholder='Your Name'
                        value={formData.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                      />
                    {/* </Form.Group> */}
                    {/* <Form.Group controlId="email"> */}
                      {/* <Form.Label>Your Email</Form.Label> */}
                      <input
                        className='gaq-input top-input width-45' 
                        type='text'
                        placeholder='Your Email'
                        value={formData.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                      />
                    {/* </Form.Group> */}
                  </div>
                  {/* <Form.Group controlId="projectDetails"> */}
                    {/* <Form.Label>Project Details</Form.Label> */}
                    <input
                      className='gaq-input width-100' 
                      type='text'
                      placeholder='Project Details'
                      value={formData.projectDetails}
                      onChange={(e) => handleInputChange('projectDetails', e.target.value)}
                    />
                  {/* </Form.Group> */}

                </div>
                  <BconButton
                    type='submit'
                    className='submit-btn border-bottom barlow-font-medium my-4 px-4'
                    onSubmit={submitHandler}
                  >
                    Submit
                  </BconButton>
              </div>
            {/* </form> */}
            <div className='font-9 contact-details d-flex flex-column align-items-center'>
              <div>Please contact:</div>
              <div className='bcon-text-red my-2'>Bijju Varghese Zacharia | +44 7935789686 | bijju@bconnectevents.com</div>
              <div className='bcon-text-red'>Betty Thomas | +44 7540580296 | betty@bconnectevents.com</div>
            </div>
          </div>
        </Modal.Body>
        <div className="close-btn-modal position-absolute">
          <img onClick={() => { onClose() }} src={closeIcon} alt="close icon" className="btn-icon" />
        </div>
      </Modal>
      {isLoading && (
                <div className="spinner-container zindex">
                    <div className="spinner"></div>
                </div>
            )}
                  <ToastContainer/>
    </div>
  );
}
