import axios from "axios"
import {apiRoutes} from "./api-routes.ts";

const {GET_EVENTS, GET_EVENT_BY_ID, GET_CATEGORIES,CREATE_EVENT,UPDATE_EVENT,GET_PRESIGNED_URL,GET_PRESIGNED_UPLOAD_URL,GET_MULTIPLE_PRESIGNED_UPLOAD_URL,GET_IS_EVENT_VALID,DELETE_IMAGE,DELETE_EVENT} = apiRoutes;

export const getEvents = (categoryId) => {
    return axios({
        method: 'GET',
        headers:{
            categoryId
        },
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_EVENTS}?fetchCover=true&limit=1000&imageLimit=1000`
    })
}

export const getEventById = (eventId, categoryId) => {
    return axios({
        method: 'GET',
        headers:{
            categoryId: categoryId
        },
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_EVENT_BY_ID.replace('{id}', eventId)}?limit=1000`
    })
}

export const getCategories = () => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_CATEGORIES}`
    })
}

export const createEventApi = (body,category) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${CREATE_EVENT}`,
        data: body,
    })
}

export const updateEventApi = (body,eventId,category) => {
    return axios({
        method:'PATCH',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${UPDATE_EVENT.replace('{id}', eventId)}`,
        data: body,
        headers:{
            categoryId: category
        },
    })
}

export const getPresignedUrl = (id) => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_PRESIGNED_URL.replace('{id}', id)}`
        
    })
}

export const getPresignedUploadUrl = () => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_PRESIGNED_UPLOAD_URL}`
    })
}

export const getMultiplePresignedUploadUrl = (count) => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_MULTIPLE_PRESIGNED_UPLOAD_URL}?quantum=${count}`
    })
}

export const getIsValidEvent = (name,categoryId) => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_PATH}/${GET_IS_EVENT_VALID}?name=${name}&categoryId=${categoryId}`
    })
}

export const deleteImage = (id, categoryId, eventId ) => {
    const headers = {
        'categoryId': categoryId,
        'eventId': eventId
    };

    return axios({
        method:'DELETE',
        url :  `${process.env.REACT_APP_API_BASE_PATH}/${DELETE_IMAGE.replace('{id}', id)}`,
        headers: headers
    })
}

export const deleteEvent = (eventId, categoryId ) => {
    const headers = {
        'categoryId': categoryId,
    };

    return axios({
        method:'DELETE',
        url :  `${process.env.REACT_APP_API_BASE_PATH}/${DELETE_EVENT.replace('{id}', eventId)}`,
        headers: headers
    })
}