import React, { useState, useRef } from "react";

function UploadButton(params) {
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    if (inputRef.current?.files) {
      setLoading(true);

      const files = inputRef.current.files;
      const reader = new FileReader();

      reader.onload = (event) => {
        // console.log("🚀 ~ file: upload-button.jsx:20 ~ handleDisplayFileDetails ~ event:", event)
        // Image is fully loaded
        // console.log(file);
        params.fn(files);
        setLoading(false);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <div className="my-3 mx-3">
      <input
        ref={inputRef}
        onChange={handleDisplayFileDetails}
        className="d-none"
        multiple={true}
        type="file"
      />
      <button
        onClick={handleUpload}
        className={`btn btn-outline-primary`}
        disabled={loading}
      >
        {"Upload Images"}
      </button>
    </div>
  );
}

export default UploadButton;
