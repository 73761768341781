import './styles.scss';


import row11 from './../../assets/Collage/Row1/1_Row1.jpg'
import row12 from './../../assets/Collage/Row1/2_Row1.jpg'
import row13 from './../../assets/Collage/Row1/3_Row1.jpg'
import row14 from './../../assets/Collage/Row1/4_Row1.jpg'
import row15 from './../../assets/Collage/Row1/5_Row1.jpg'
import row16 from './../../assets/Collage/Row1/6_Row1.jpg'
import row17 from './../../assets/Collage/Row1/7_Row1.jpg'
import row18 from './../../assets/Collage/Row1/8_Row1.jpg'
import row19 from './../../assets/Collage/Row1/9_Row1.jpg'
import row111 from './../../assets/Collage/Row1/11_Row1.jpg'
import row112 from './../../assets/Collage/Row1/12_Row1.jpg'

import row21 from './../../assets/Collage/Row2/1_Row2.jpg'
import row22 from './../../assets/Collage/Row2/2_Row2.jpg'
import row23 from './../../assets/Collage/Row2/3_Row2.jpg'
import row24 from './../../assets/Collage/Row2/4_Row2.jpg'
import row25 from './../../assets/Collage/Row2/5_Row2.jpg'
import row26 from './../../assets/Collage/Row2/6_Row2.jpg'
import row27 from './../../assets/Collage/Row2/7_Row2.jpg'
import row28 from './../../assets/Collage/Row2/8_Row2.jpg'
import row29 from './../../assets/Collage/Row2/9_Row2.jpg'

import row31 from './../../assets/Collage/Row3/1_Row3.jpg'
import row32 from './../../assets/Collage/Row3/2_Row3.jpg'
import row33 from './../../assets/Collage/Row3/3_Row3.jpg'



export default function Collage(){
    const row1Imgs = [
        row11,
        row12,
        row13,
        row14,
        row15,
        row16,
        row17,
        row18,
        row19,
        row111,
        row112,
    ]

    const row2Imgs = [
        row26,
        row25,
        row23,
        row21,
        row22,
        row24,
        row27,
        row28,
        row29,
    ]
    
    const row3Imgs = [
        row31,
        row32,
        row33,
    ]
    
    return (
        <div className='bg-white div-collage-container'>
            <div className="collage-container barlow-font-regular bg-white d-flex justify-content-between align-items-center">
                <div className='image-1'>
                    {row1Imgs.map((img, key)=>{
                        return <img key={key} className={`${key%2!==0 ? 'border-red' : ''} width-100`} src={img} /> 
                    })}
                </div>
                <div className='image-2'>             
                    {row2Imgs.map((img, key)=>{
                        return <img key={key} className={`${key%2!==0 ? 'border-red' : ''} width-100`} src={img} /> 
                    })}
                </div>
                <div className='image-3'>
                    {row3Imgs.map((img, key)=>{
                        return <img key={key} className={`${key%2!==0 ? 'border-red' : ''} width-100`} src={img} /> 
                    })}
                </div>
            </div>
        </div>
    )
}