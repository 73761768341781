import React ,{useState,useEffect,useCallback} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import bin from "./../../../assets/bin.png";
import axios from "axios"

import './styles.scss';

import ImageUploaderWithPriority from '../image-uploader/image-uploader';
import ReactSelect from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

import { getCategories , getEventById, createEventApi,updateEventApi, getPresignedUploadUrl,getMultiplePresignedUploadUrl, getIsValidEvent,deleteImage} from '../../../api/events.api';
import { useNavigate } from 'react-router-dom';
const imgs = [];


const Events = ({eventId,categoryId}) => { 
    const customStyles = {
      option: (base, { isFocused }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#be4545" : "",
        color: isFocused ? "white" : "black"
        };
      }
    }
    const [event, setEvent] = useState(null);
    const [imagesToRender, setImagesToRender] = useState([]);
    const [categories, setCategories] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]); //[{imageObj:File,isCover:false}]
    const [isLoading, setIsLoading] = useState(false);
    const [coverImgs, setCoverImgs] = useState([]);
    const [eventData, setEventData] = useState({
      name: '',
      location: '',
      startDate: '',
      description: '',
      category:'',
      images: [],
      imagesPriority:[]
    });
  

    // use effect to load categoryDetail
    // need to be done on first load only
    useEffect(()=>{
        const loadDetails = async () =>{
            await getCategoriesList();
        }
        loadDetails();
    },[])
  

    //use effect to run when we are getting the event details to update the event
    useEffect(()=>{
        if (event != null && event.images != null){
            if (event.images.images != null && event.images.images?.length!=0){
            setImagesToRender([...event.images.images])
        }
     }
    },[event])

    // use effect when update event is called to fetch the event details.
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (eventId  && categoryId && eventId !== '' && categoryId !== '') {
                    await getEventByIdAndSetState(eventId, categoryId);
                }
            } catch (error) {
            }
        };
        fetchData();
    }, [categoryId, eventId]);

    const getEventByIdAndSetState = async (eventId, categoryId) => {
        try {
          const response = await getEventById(eventId, categoryId);
          setEvent(response?.data.data || null);
          setEventData((prevEvent) => ({
            ...prevEvent,
            ...response?.data.data,
          }));
        } catch (error) {
        }
      };
  

    const maxCharacters = 1000; // Adjust this to your desired character limit
  
    const handleDescriptionChange = (e) => {
  
        const inputValue = e.target.value;
        if (inputValue.length <= maxCharacters) {
            setEventData((prevEventData) => ({
            ...prevEventData,
            description: inputValue,
            }));
        }else{
      
        }
    };

    const handleImageUpload = (image) => {
        setTimeout(() => {
            setIsLoading(false)
          }, 1000);
  
        const newImages = [];
        Array.from(image).forEach((img)=>{
          newImages.push({imgName: img.name, imageObj:img,isCover:false});
        })
  
        setUploadedImages([...uploadedImages, ...newImages])
    }

    const deleteNewUploadedImg = (img) =>{
        setIsLoading(true);
        setTimeout(()=>{
            setIsLoading(false);
            },100)
        let tempArr = coverImgs
        const idx = tempArr.indexOf(img);
        if(idx > -1){
            tempArr.splice(idx, 1);
            setCoverImgs([...tempArr])
        }
        const updatedUploadedImages = uploadedImages.filter((image)=>{
            return image.imgName !== img;
        });
        setUploadedImages([...updatedUploadedImages]);   
    }
  

    const deleteImg =  (img) => {
        setIsLoading(true)
        let deleteImageInDb = ""
        deleteImageInDb = img.imageUrl.split(".com/")[1].split("?")[0];
        try {
            // give a modal to delete the image that will delete the image permanently
            deleteImage(deleteImageInDb,categoryId,eventId).then((res)=>{
                if (res && res?.status && res?.status == 200){
                    // Successful delete
                    // Update the eventData array
                    const updatedImages = [...event.images.images];
                    let filteredImaged = updatedImages.filter((image)=>{
                        return deleteImageInDb != image.imageUrl.split(".com/")[1].split("?")[0];
                    })

                    // Update the state with the new array without the deleted image
                    setImagesToRender([...filteredImaged])
                    setIsLoading(false)
                }else{
                    // unable to delete the image
                    toast.error("Unable to delete image, try again")
                    setIsLoading(false)
                }
            })
        }catch(err) {
            setIsLoading(false)
            toast.error("Error")
        }
}
  
    const getCategoriesList = async () => {
        const categories = await getCategories();
        const categoryAdapter = [];
        categories.data.data.forEach((category)=>{
            categoryAdapter.push({label: category.name, value: category.id});
        })
        setCategories(categoryAdapter);
    }


    const updateEventImages = async () => {
        try {    
            const rept = Math.ceil(uploadedImages.length / 5) 
            let arrSize = uploadedImages.length
            let itr = 0; 
            let eventImages = []

            for (let i = 0; i < rept; i++,arrSize-=5) {
                const quantum = Math.min(5, arrSize);
                const signedUrlResponses = await getMultiplePresignedUploadUrl(quantum);
                const signedUrls = signedUrlResponses.data.data;
                const uploadPromises = signedUrls.map(async (signedUrl) => {
                    try {
                        
                        let isImageCover = uploadedImages[itr].isCover
                        var image = uploadedImages[itr++].imageObj
                        const filename = signedUrl.split('.com/')[1].split('?')[0];
                        eventImages = [...eventImages,{isCover: isImageCover, imageUrl: filename}]
                        imgs.push({isCover: isImageCover, imageUrl: filename});
                        await axios.put(signedUrl, image, {
                            headers: {
                                'Content-Type': image.type,
                            },
                        })
                    } catch (error) {
                        // Handle errors if necessary
                        // console.error('Error uploading to signed URL:', error);
                    }
                });
                const resp = await Promise.all(uploadPromises)
                
            }
            setEventData({ ...eventData, images: [...eventData.images, ...eventImages]});
        }catch (error) {
            // console.error('Error updating event images:', error);
        }
    }
  
    const updateEvent = async (categoryId,eventId) => {
      if (event !== null) {
        const updatedFields = {};
        const imagePriority = [];
        for (let i = 0; i < imagesToRender.length; i++) {
          let imageUrl = imagesToRender[i].imageUrl.split("com/")[1].split("?")[0];
          imagePriority.push(imageUrl);
        }
        for (let i = 0; i < imgs.length; i++) {
          imagePriority.push(imgs[i].imageUrl);
        }

        eventData.imagesPriority  = imagePriority
        Object.keys(eventData).forEach((key) => {
            if (eventData[key] !== event[key]) {
                updatedFields[key] = eventData[key];
            }
        });

        if (Object.keys(updatedFields).length > 0) {
          setEventData((prevEventData) => ({
            ...prevEventData,
            ...updatedFields,
          }));
        }

        // make call 
        await updateEventApi(eventData,eventId,categoryId)
      }
    };
  
    const createEvent = async () => {
      try {
        await createEventApi(eventData);
      
        toast.success('Event Created Successfully');
        setTimeout(()=>{
          window.location.reload()
        },500)
      } catch (error) {
        // console.error('Error creating event', error);
      }
    };

  
    const handleCoverImgs = (img) =>{
        // modal to verify => api call 
    }

    const handleNewUploadedCoverImgs = (img) => {
      
      let newCoverImgs = []
      if(coverImgs.indexOf(img) < 0){
        if(coverImgs.length === process.env.REACT_APP_MAX_COVER_IMGS){
          toast.error('Maximum cover limit reached');
          return;
        }
        const updatedUploadedImages = uploadedImages.map((image)=>{
          if(image.imgName === img){
            return {...image, isCover: true}
          }
          else{
            return image;
          }
        });
        setUploadedImages([...updatedUploadedImages]);
        setCoverImgs([...coverImgs, img]);
      }
      else{
        newCoverImgs = coverImgs.filter((image)=>image!==img);
        setCoverImgs([...newCoverImgs]);
        const updatedUploadedImages = uploadedImages.map((image)=>{
          if(image.imgName === img){
            return {...image, isCover: false}
          }
          else{
            return image;
          }
        });
        setUploadedImages([...updatedUploadedImages]);
      }
    }

    const checkIsNameValid = async () =>{
        try {
            // Make an API call to check if the event name is valid
            const response = await getIsValidEvent(eventData.name, eventData.category)
            // Assuming your API returns a boolean field 'isValid'
            
            if (response.data.data.isValid) {
              // Event name is valid, proceed with the form submission
              return true;
            } else {
              // Event name is not valid, throw an error
              throw new Error('Invalid event name');
            }
          } catch (error) {
            // Handle API call errors or validation errors
            toast.error('Same Name Event Already Exists');
            throw error; // Rethrow the error to be caught in the submitForm function
          }
    }

    const submitForm = async () => {
      try {
        // iterate over the images array and find imageUrl, and use that to create event 
        setIsLoading(true)

        if (eventId && categoryId && eventId !== '' && categoryId !== '') {
            await updateEventImages();
            eventData.images = imgs;
            await updateEvent(categoryId,eventId);
        } else {
            await checkIsNameValid();
            await updateEventImages();
            eventData.images =  imgs;
            await createEvent();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        // console.error('Error submitting frm:', error);
        // toast.error(er)
      }
    };

    return (
        <div className='create-events-form d-flex justify-content-center align-items-start p-5'>
          <div className='width-70'>
          <p className='text-dark font-20 mb-5'>Create Events</p>
            <Container>
                <Row className=''>
                    <Col className='col-col' sm={12}>
                        <Form.Group className="mb-3">
                        <Form.Label className="events-form-label">Name <span className='mandatory text-danger'>*</span> </Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Event Name" 
                        value={event?.name} 
                        onChange={(e) => setEventData({ ...eventData, name: e.target.value })}
                        />
                        </Form.Group>
                    </Col>
                    <Col className='col-col' sm={12}>
                        <Form.Group className="mb-3" controlId="formTextarea">
                        <Form.Label className="events-form-label"> Location  <span className='mandatory text-danger'>*</span> </Form.Label>
                        <Form.Control 
                        type="text"  
                        value={event?.location}
                        placeholder="Location" 
                        onChange={(e) => setEventData({ ...eventData, location: e.target.value })}/>
                        </Form.Group>
                    </Col>
                    <Col className='col-col' sm={12}>
                      <Form.Label className="events-form-label">Category  <span className='mandatory text-danger'>*</span> </Form.Label>
                        <ReactSelect 
                        aria-label="Default select example" 
                        options={categories} 
                        value={event?.category} 
                        label='Category'
                        styles={customStyles}
                        onChange={(selectedOption) => setEventData(selectedOption ? { ...eventData, category: selectedOption.value }:{...eventData})}/>
                    </Col>
                    <Col className='col-col' sm={12}>
                    <Form.Group className="mb-3" controlId="formDate">
                        <Form.Label className="events-form-label">Date <span className='mandatory text-danger'>*</span> </Form.Label>
                        <Form.Control 
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        onChange={(e) => setEventData({ ...eventData, start_date: e.target.value })} 
                        />
                        </Form.Group>
                    </Col>
                    <Col className='col-col' sm={12}>
                        <Form.Group className="mb-3" controlId="description">
                        <Form.Label className="events-form-label">Description <span className='mandatory text-danger'>*</span> </Form.Label>
                        <Form.Control 
                        as="textarea" 
                        rows={3}
                        onChange={handleDescriptionChange}/>
                        </Form.Group>
                    </Col>
                    <Col className='' sm={12}>
                      <div className='d-flex align-items-center'>
                        <ImageUploaderWithPriority uploadImg = {(img)=>{handleImageUpload(img)}}/>
                        <Button disabled={!(eventData.name && eventData.category && coverImgs.length > 0 && eventData.description && eventData.start_date && uploadedImages.length > 0 && eventData.location)} className='btn' onClick={submitForm}>Save Details</Button>
                        </div>
                    </Col>  
                </Row>x
              </Container>
          </div>
          <div className='uploaded-images width-100 d-flex justify-content-center align-items-start flex-wrap'>
              {imagesToRender.length > 0 ? imagesToRender.map((image, index)=>{ 
                return (
                  <div className='position-relative width-70 d-flex'>
                    <div className={`${coverImgs && coverImgs.length > 0 && coverImgs.indexOf(image.imgName) > -1 ? 'cover-img' : ''} uploaded-img-img m-3 width-100 position-relative`} key={index}>
                      <img className='width-100 uploaded-img' src={image.imageUrl} alt={`img-${index}`} />
                    </div>
                      <img onClick={()=>{deleteImg(image)}} src={bin} className='img-bin position-absolute' alt="" />
                    </div>
                )
              }) : ''}
              {uploadedImages.length > 0 ? uploadedImages.map((image, index)=>{

                return (
                <div className='position-relative width-70 d-flex'>
                    <div onClick={()=>{handleNewUploadedCoverImgs(image.imgName)}} className={`${coverImgs && coverImgs.length > 0 && coverImgs.indexOf(image.imgName) > -1 ? 'cover-img' : ''} uploaded-img-img m-3 width-100 position-relative`} key={index}>
                    <img className='width-100 uploaded-img' src={URL.createObjectURL(image.imageObj)} alt={`img-${index}`} />
                    </div>
                    <img onClick={()=>{deleteNewUploadedImg(image.imgName)}} src={bin} className='img-bin position-absolute' alt="" />
                    </div>
                )
                }) : ''}
          </div>
          {isLoading && (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            )}
                    
          <ToastContainer
            autoClose={5000}
            draggable={true}
            pauseOnHover={true}
            icon={false}
            hideProgressBar={true}
          />
    </div>
  );
};

export default Events