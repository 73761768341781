import './styles.scss';
import browseIcon from './../../../../assets/icons/browse.png';
import { Fade } from 'react-reveal';
import { Carousel } from 'react-bootstrap';
import moment from "moment";

export default function EventCard(props){
    const event = props?.event;
    const toggleModal = props.toggleModal;

    return (
        <Fade>
            <div onMouseOver={()=>{setInterval(500)}} onMouseOut={()=>{setInterval(null)}} onClick={()=>{toggleModal(event?.id, event?.category)}} className="event-card bg-white d-flex my-4 flex-column">
                <Carousel className='event-carousel-item d-flex service-item service-type-right' controls={false} slide={true} interval={1500} indicators={false}>
                    {event?.images?.images?.map((image)=>{
                        return (
                            <Carousel.Item className='event-carousel-item'>
                            <div style={{backgroundImage: `url(${image.imageUrl})`}} className="event-type-image"></div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                <div className='d-flex justify-content-between card-details'>
                    <div className='d-flex flex-column'>
                        <div className="event-card-name bcon-text-red font-12 barlow-font-medium">
                            {event?.name || ''}
                        </div>
                        <div className="event-card-year text-gray">
                            {moment(event.startDate).year()}
                        </div>
                    </div>
                    <div className="event-browse-icon d-flex align-items-center">
                        <img src={browseIcon} className='width-100 browse-icon-img' />
                    </div>
                </div>
            </div>
        </Fade>
    )
}