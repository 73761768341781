import './styles.scss';
import ourClients from './../../assets/our-clients.png';
import client1 from './../../assets/clients/1.jpeg';
import client2 from './../../assets/clients/2.png';
import client3 from './../../assets/clients/3.png';
import client4 from './../../assets/clients/4.png';
import client5 from './../../assets/clients/5.png';
import client6 from './../../assets/clients/6.png';
import client7 from './../../assets/clients/7.png';
import client8 from './../../assets/clients/8.png';
import client9 from './../../assets/clients/9.png';
import client10 from './../../assets/clients/10.png';
import client11 from './../../assets/clients/11.png';
import client12 from './../../assets/clients/12.png';
import client13 from './../../assets/clients/13.png';
import client14 from './../../assets/clients/14.png';
import client15 from './../../assets/clients/15.png';
import client16 from './../../assets/clients/16.png';
import client17 from './../../assets/clients/17.png';
import client18 from './../../assets/clients/18.png';
import client19 from './../../assets/clients/19.png';
export default function OurClients(){
    const images = [
        client1,
        client2,
        client3,
        client4,
        client5,
        client6,
        client7,
        client8,
        client9,
        client10,
        client11,
        client12,
        client13,
        client14,
        client15,
        client16,
        client17,
        client18,
        client19
    ];
    return (
        <div className='d-flex justify-content-center'>
            <div className="our-clients width-80 flex-wrap d-flex justify-content-center align-items-center">
                {/* {images.map((image)=>{
                    return (
                        <div className='our-clients-div m-4 overlay-cls'>
                            <img src={image} className="width-60 our-clients-img" />
                        </div>
                    )
                })}
                 */}
                <div className='d-flex justify-content-center align-items-center clients-group'>
                        <div className='our-clients-div big-img overlay-cls'>
                            <img src={client14} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div big-img overlay-cls'>
                            <img src={client16} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client10} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client15} className="width-60 our-clients-img" />
                        </div>
                </div>
                <div className='d-flex justify-content-center align-items-center clients-group'>
                        <div className='big-img our-clients-div overlay-cls'>
                            <img src={client4} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client5} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client6} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div big-img overlay-cls'>
                            <img src={client11} className="width-60 our-clients-img" />
                        </div>
                </div>
                <div className='d-flex justify-content-center align-items-center clients-group'>
                        <div className='white-bg our-clients-div overlay-cls'>
                            <img src={client7} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client3} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div big-img overlay-cls'>
                            <img src={client12} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client13} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div white-bg overlay-cls'>
                            <img src={client17} className="width-60 our-clients-img" />
                        </div>
                </div>
                <div className='d-flex justify-content-center align-items-center clients-group'>
                        <div className='white-bg our-clients-div overlay-cls'>
                            <img src={client18} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client9} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client8} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div big-img overlay-cls'>
                            <img src={client19} className="width-60 our-clients-img" />
                        </div>
                </div>
                <div className='d-flex justify-content-center align-items-center clients-group'>
                        <div className='white-bg big-img our-clients-div overlay-cls'>
                            <img src={client1} className="width-60 our-clients-img" />
                        </div>
                        <div className='our-clients-div overlay-cls'>
                            <img src={client2} className="width-60 our-clients-img" />
                        </div>
                </div>
            </div>
        </div>
    )
}