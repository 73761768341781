import './styles.scss';

export default function HeroImage(props){
    const title = props.title || '';
    const heading = props.heading || '';
    const bgImg = props.bgImg;
    return (
        <div
        className='service-hero barlow-font-regular align-items-center flex-column text-center text-white d-flex justify-content-center'>
            <div className="heading font-20">
                {title} <br/> 
                <span className='bearpaw-font services-font font-60 lh-1 bcon-text-red'>{heading}!</span>
            </div>
            <div className="hero-img-banner">
                <img src={bgImg} alt="" className='hero-img-banner-img width-100'/>
            </div>
        </div>
    )
}