import Collage from '../collage/collage.component';
import Footer from '../footer/footer.component';
import Navbar from '../navbar/navbar.component';
import GetInTouch from './service-body/get-in-touch/get-in-touch';
import ServiceBody from './service-body/service-body.component';
import HeroImage from './hero-image/hero-image.component';
import bg from './../../assets/our-services-hero.png';

import './styles.scss';
import GetAQuote from '../get-a-quote/get-a-quote.component';
import { useState } from 'react';

export default function Services(){
    const [isModalVisible, setIsModalVisible] = useState(false);

    const modalToggle = () => {
        setIsModalVisible(!isModalVisible);
    }
    return (
        <div className='fade-in-animation'>
            <Navbar toggleModal={()=>{modalToggle()}}/>
            <HeroImage bgImg={bg} heading="Our Services"/>
            <ServiceBody/>
            <Collage/>
            <Footer/>
            <GetAQuote key={Date.now()} isVisible={isModalVisible} onClose={()=>{modalToggle()}}/>
        </div>
    )
}