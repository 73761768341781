import { Modal } from 'react-bootstrap';
import './styles.scss';
import closeIcon from './../../assets/icons/close-icon.png';
import deleteIcon from './../../assets/delete.svg';
import bconLogo from './../../assets/bconnect-logo.png';
import { useState } from 'react';
import BconButton from '../../components/button/button.component';

export default function ConfirmDelete(props){

    const isVisible = props.isVisible;
    const onClose = props.onClose;
    return (
        <div className="gaq barlow-font-regular">
            <Modal onHide={()=>{onClose(false)}} contentClassName="position-relative info-modal p-5" dialogClassName="modal-dialog" show={isVisible} style={{opacity:1}} centered className="width-100">
                <Modal.Header className='barlow-font-medium d-flex justify-content-between bcon-text-red font-20 border-0'>
                        <div className="delete-logo">
                            <img src={deleteIcon} alt="" />
                        </div>
                </Modal.Header>
                <Modal.Body className='gaq-modal-body'>
                    <div className='d-flex justify-content-between flex-column align-items-center width-100'>
                        <div className='d-flex flex-column justify-column-center font-20 align-items-center width-100 mt-3 form-part-gaq'>
                            Are you sure you want to delete this event?
                        </div>
                        <div className="btns d-flex">
                            <div onClick={()=>{onClose(true)}} className='px-4 py-3 confirm-delete mx-5'>Yes, Delete</div>
                            <div className='btn btn-outline-secondary px-4 py-3'>Cancel</div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="close-btn-modal position-absolute">
                    <img onClick={()=>{onClose(false)}} src={closeIcon} alt="close icon" className="btn-icon" />
                </div>
            </Modal>
        </div>
    )
} 