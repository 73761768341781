import BconButton from '../../../../components/button/button.component';

import './styles.scss';
import { Carousel } from 'react-bootstrap';

import musicCover1 from './../../../../assets/service-page/Music-Festival-Concerts/Cover-Page/1.jpg';
import musicCover2 from './../../../../assets/service-page/Music-Festival-Concerts/Cover-Page/2.jpg';
import musicCover3 from './../../../../assets/service-page/Music-Festival-Concerts/Cover-Page/3.jpg';

import corporateCover1 from './../../../../assets/service-page/Corporate-Exhibition/Cover-images/1.jpg';
import corporateCover2 from './../../../../assets/service-page/Corporate-Exhibition/Cover-images/2.jpg';
import corporateCover3 from './../../../../assets/service-page/Corporate-Exhibition/Cover-images/3.jpg';

import socialCover1 from './../../../../assets/service-page/Weddings-Social/Cover-Images/1.jpg';
import socialCover2 from './../../../../assets/service-page/Weddings-Social/Cover-Images/2.jpg';

import conferenceCover1 from './../../../../assets/service-page/Conferences-Summits/Cover-Page/1.jpg';
import conferenceCover2 from './../../../../assets/service-page/Conferences-Summits/Cover-Page/2.jpg';
import conferenceCover3 from './../../../../assets/service-page/Conferences-Summits/Cover-Page/3.jpg';
import conferenceCover4 from './../../../../assets/service-page/Conferences-Summits/Cover-Page/4.JPG';
import conferenceCover5 from './../../../../assets/service-page/Conferences-Summits/Cover-Page/5.JPG';


import { useEffect, useState } from 'react';

export default function ServiceType(props){
    const heading = props.heading || '';
    const description = props.description || '';
    const onClick = props.onServiceClick;

    const [images, setImages] = useState([]);
    
    const socialBgs = [
        socialCover1, socialCover2
    ];
    
    const corporateBgs = [
        corporateCover1, corporateCover2, corporateCover3
    ];

    const musicBgs = [
        musicCover1, musicCover2, musicCover3
    ];
    
    const conferenceBgs = [
        conferenceCover1, conferenceCover2, conferenceCover3, conferenceCover4, conferenceCover5
    ];
    useEffect(()=>{
        if(heading === "Conference & Summits"){
            setImages(conferenceBgs)
        }
        else if(heading === "Corporate & Exhibitions"){
            setImages(corporateBgs)
        }
        else if(heading === "Music Festival & Concerts"){
            setImages(musicBgs)
        }
        else if(heading === "Weddings & Social Events"){
            setImages(socialBgs)
        }
    },[])
    
    
    return (
        <div className="service-type d-flex">
            <div className="service-type-left d-flex justify-content-between flex-column width-60">
                <div className="width-80 left-content-service d-flex flex-column">
                    <div>
                        <div className="service-type-heading barlow-font-bold lh-1 font-25">
                            {heading}
                        </div>
                        <div className="service-type-description font-12">
                            {description}
                        </div>
                    </div>
                </div>
                <div onClick={()=>{onClick(heading)}}>
                    <BconButton className="width-20 bcon-service-btn" btnText="View All Images"/>
                </div>
            </div>
            <Carousel className='service-carousel-item service-carousel d-flex service-item service-type-right width-40' controls={false} slide={true} interval={2500} indicators={false}>
                {images.length > 0 && images.map((image)=>{
                    return (
                        <Carousel.Item className='service-carousel-item'>
                        {/* <div style={{backgroundImage: `url(${image})`}} className="service-type-image responsive-padding"></div> */}
                        <img src={image} alt="" className='service-carousel-img' />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
}