import Navbar from '../../navbar/navbar.component'
import './styles.scss'
import { useEffect, useState } from 'react';
import { getEvents, getCategories } from '../../../api/events.api';
import AdminEventItem from './admin-event-item/admin-event-item';
import ReactSelect from 'react-select';
import { deleteEvent } from '../../../api/events.api';
import { Link } from 'react-router-dom';
import pageNotFound from './../../../assets/not-found.jpg';

export default function UpdateEvent(){
    const [events, setEvents] = useState([]);
    const [category, setCategory] = useState({value: null, label: 'All'});
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const customStyles = {
        option: (base, { isFocused }) => {
        return {
          ...base,
          fontSize: '.9rem',
          backgroundColor: isFocused ? "rgba(190, 68, 68, 0.4)" : "",
          color: isFocused ? "black" : ""
        };
      }
    };
    useEffect(()=>{
        getCategoriesList();
        getEventData();
    },[category])
    const getEventData = async () => {
        const eventsData = await getEvents(category?.value);
        setEvents(eventsData?.data?.data?.data || []);
        setIsLoading(false);
    }
    const getCategoriesList = async () => {
        setIsLoading(true)
        const categories = await getCategories();
        const categoryAdapter = [{label: 'All', value: null}];
        categories.data.data.forEach((category)=>{
            categoryAdapter.push({label: category.name, value: category.id});
        })
        setCategories(categoryAdapter);
    }
    const handleDelete = async (event) => {
        setIsLoading(true);
        await deleteEvent(event.id, event.category);
        await getEventData();
        setIsLoading(false);    
    }

    return (
        <>
            <Navbar/>
            <div className="update-events px-5 py-5">
            <Link className='text-decoration-none' to={`/admin/create-update-events`}>
                <div className="create-event-btn pb-5 pt-3 d-flex justify-content-end text-white">
                    <div className='create-event-btn-btn px-4 py-2'>Create Event</div>
                </div>
            </Link>
                <div className='font-20 d-flex justify-content-between'>
                    <div>All Events</div>
                    <div className='width-20 d-flex align-items-center'>
                        <div className='width-60 font-9'>Category:</div>
                            <ReactSelect
                                value={category}
                                className='width-100 font-9'
                                options={
                                    categories
                                }
                                onChange={(e)=>{
                                    setCategory(e);
                                }}
                                styles={customStyles}
                            />
                        </div>
                    </div>
                {events.length > 0 && events.map((event)=>{
                    return <AdminEventItem handleDelete={(e)=>{handleDelete(e)}} event={event}/>
                })}
                {!isLoading && events.length < 1 && 
                                    <div className='width-100 d-flex justify-content-center'>
                                    <img src={pageNotFound}  className='width-50'/>
                                </div>
                                }
                </div>
                {isLoading && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
        </>
    )
}